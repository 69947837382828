import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    transition: all 0.3s ease-out;
    background: ${(p) => p.theme.pale_blue};
    transition: 250ms;
    border-color: ${(p) => p.theme.pale_blue};
  }

  ${(props) =>
    props.design === 'primary'
      ? `background-color: ${props.theme.white};
  color: ${props.theme.dark_blue};
  border: 1px solid ${props.theme.white};`
      : null}

  ${(props) =>
    props.design === 'dark'
      ? `background-color: ${props.theme.dark_blue};
  border: 1px solid ${props.theme.dark_blue};
  & p {
    color: ${props.theme.white};
  }`
      : null}

  ${(props) =>
    props.design === 'outline'
      ? `background-color: transparent;
  color: ${props.theme.white};
  padding: 0.5rem 1.25rem;
  border: 1px solid ${props.theme.bright_blue};
  transition: all 0.3s ease-out;`
      : null}

${(props) =>
    props.size === 'medium'
      ? `padding: 0.5rem 1.25rem;
  font-size: 1.125rem;`
      : null}

${(props) =>
    props.size === 'large'
      ? `padding: 0.75rem 1.625rem;
  font-size: 1.25rem;
  max-width: calc(48rem - 2rem);`
      : null}

${(props) => (props.size === 'full' ? 'width: 100%;' : null)}
`;
