import React, { useEffect } from 'react';

import googleAnalytics from 'utils/googleAnalytics';
import { Background, CardDeck, TextCard } from 'components/Common/Common';
import { ContactDirect, ContactIndirect } from './components/Contact';

const ContactPage = () => {
  useEffect(() => {
    googleAnalytics();
  }, []);

  return (
    <Background bgColor="#fafafa">
      <CardDeck>
        <TextCard>
          <ContactDirect />
        </TextCard>
        <TextCard>
          <ContactIndirect />
        </TextCard>
      </CardDeck>
    </Background>
  );
};

export default ContactPage;
