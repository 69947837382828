import styled from 'styled-components';

export const PostTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: 600;
  margin: 2rem 0;
  text-align: left;
  padding-left: 1.5rem;
`;

export const PostBody = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
`;

export const PostImage = styled.img`
  margin: 1rem 0;
`;

export const DetailedPostWrapper = styled.div`
  max-width: 64rem;
  margin: auto;
`;
