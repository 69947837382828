import { Checkbox, Form, Space } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  padding: 1rem;
  max-width: 50rem;

  & .ant-form-item-label > label {
    font-size: 1rem;
  }
`;

export const ReachOutLabel = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: ${(p) => p.theme.black};
`;

export const ThanksSections = styled.div`
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & h3 {
    color: #070042;
    margin-bottom: 1rem;
  }
`;

export const FormWrapper = styled.div`
  min-height: 20rem;
  display: flex;
  flex-direction: column;
`;

export const StyledTagGroup = styled(Space)`
  margin: 1rem 0;

  .ant-tag-checkable {
    font-size: 0.875rem;
    padding: 0.5rem;
    border-color: #70bff9;
  }

  .ant-tag-checkable-checked {
    background-color: ${(p) => p.theme.light_blue};
    border-color: ${(p) => p.theme.light_blue};
    font-weight: 600;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 1.25rem;
    height: 1.25rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(p) => p.theme.light_blue};
    border-color: ${(p) => p.theme.light_blue};
  }
`;
