import React from 'react';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'utils/useWindowDimensions';
import { ContentContainer, Background } from 'components/Common/Common';
import { newTabButton } from 'components/CalculatorButton/CalculatorButton';
import { CalculatorContainer } from './styled';

export const SolarCalculator = () => {
  const { t } = useTranslation('translation');
  const { size } = useWindowDimensions();

  const solarCalculatorCard = (
    <CalculatorContainer>
      <iframe
        title="iframe-eturnity"
        frameBorder="0"
        src="https://solarrechner.eturnity.io/solarwatt/?utm_source=Solarbaer&utm_medium=SolarcalculatorDE"
      />
    </CalculatorContainer>
  );

  return (
    <Background bgColor="#dadada">
      <ContentContainer bgColor="#dadada">
        {size === 'xs' || size === 'sm' || size === 'md' ? newTabButton(t) : solarCalculatorCard}
      </ContentContainer>
    </Background>
  );
};
