import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import Routing from 'pages/Routing';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Suspense fallback={<div>Loading... </div>}>
        <Routing />
      </Suspense>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <Suspense fallback={<div>Loading... </div>}>
        <Routing />
      </Suspense>
    </React.StrictMode>,
    rootElement
  );
}
