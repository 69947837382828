import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const CookiesConsent = () => {
  const { t } = useTranslation('translation');

  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  return (
    <CookieConsent
      buttonText={t('cookiesConsent.accept_btn')}
      enableDeclineButton
      declineButtonText={t('cookiesConsent.decline_btn')}
      flipButtons
      overlay
      overlayClasses="cookie-consent-overlay"
      buttonWrapperClasses="cookie-consent-buttons"
      contentClasses="cookie-consent-content"
      location=""
      style={{
        background: '#ffffff',
        color: 'rgb(7, 0, 66)',
        alignItems: 'center',
        maxWidth: '34.375rem',
        borderRadius: '0.5rem',
        left: 'unset',
      }}
      buttonStyle={{
        color: 'rgb(7, 0, 66)',
        background: '#a2d1f4',
        borderRadius: '0.5rem',
        fontSize: '1rem',
        display: 'block',
        width: '95%',
        padding: '0.625rem',
      }}
      declineButtonStyle={{
        borderRadius: '0.5rem',
        fontSize: '1rem',
        marginTop: '0.625rem',
        width: '95%',
        padding: '0.625rem',
        background: '#ffffff',
        border: '1px solid rgb(7, 0, 66)',
        color: 'rgb(7, 0, 66)',
      }}
      onDecline={handleDeclineCookie}>
      {t('cookiesConsent.description_text')}
    </CookieConsent>
  );
};

export default CookiesConsent;
