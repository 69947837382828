import React from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm from 'components/ContactForm/ContactForm';

export const ContactDirect = () => {
  const { t } = useTranslation('translation');

  return (
    <>
      <h1>{t('common.direct_contact')}</h1>
      <p>{t('common.phone')} +49 (0) 1520 4227023</p>
      <p>{t('common.mail')} alexander.meister@photovoltaik-solarbaer.de</p>
    </>
  );
};

export const ContactIndirect = () => {
  const { t } = useTranslation('translation');

  return (
    <>
      <h1>{t('common.make_appointment')}</h1>
      <ContactForm />
    </>
  );
};
