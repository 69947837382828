import React from 'react';
import { useTranslation } from 'react-i18next';

import SolarCalculatorImg from 'assets/images/SolarCalculator.png';
import { newTabButton } from '../CalculatorButton/CalculatorButton';
import { ContentBox, ImageTitleWrapper, Description } from './styled';

const SolarCalculatorBox = ({ noTitle }) => {
  const { t } = useTranslation('translation');

  return (
    <ContentBox>
      <ImageTitleWrapper>
        <img src={SolarCalculatorImg} alt="Solar calculator" />
        {!noTitle && <h5>{t('common.solar_calculator_title')}</h5>}
      </ImageTitleWrapper>
      <Description>{t('common.solar_calculator_subtitle')}</Description>
      {newTabButton(t)}
    </ContentBox>
  );
};

export default SolarCalculatorBox;
