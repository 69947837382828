import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import LeadService from 'services/LeadService';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import { FormWrapper } from './styled';
import FifthStep from './FifthStep';
import SixthStep from './SixthStep';

const ContactForm = () => {
  const { t } = useTranslation('translation');

  const [step, setStep] = useState(1);
  const [leadId, setLeadId] = useState();
  const [formValues, setFormValues] = useState({
    phone: undefined,
    email: undefined,
    storage_yes_no: 'no',
    charging_technology: 'no',
    to_what_extent_already_informed_about_pv: 'no',
    storage_info: '',
    emergency_power_yes_no: 'none',
    e_car_type_comment: '',
    phone_appointment_other_info: '',
    household_electricity_consumption: 0.0,
    first_name: '',
    last_name: '',
    street: '',
    street2: '',
    zip: '',
    city: '',
  });

  const onFirstStepSubmit = async (values) => {
    try {
      if (!formValues.email && !formValues.phone) {
        const res = await LeadService.createLead(values);
        setLeadId(res.data.result.id);
        setFormValues((prevValues) => ({ ...prevValues, ...values }));
      }
      setStep(2);
    } catch (error) {
      toast.error(t('contactForm.something_went_wrong'));
    }
  };

  const onNextStepSubmit = async (values) => {
    if (step === 2) {
      try {
        setStep(3);
        await LeadService.updateLead(leadId, {
          to_what_extent_already_informed_about_pv: formValues.to_what_extent_already_informed_about_pv,
          storage_yes_no: formValues.storage_yes_no,
          charging_technology: formValues.charging_technology,
        });
      } catch (error) {
        toast.error(t('contactForm.something_went_wrong'));
      }
      return;
    }

    try {
      setFormValues((prevValues) => ({ ...prevValues, ...values }));
      setStep(step + 1);
      await LeadService.updateLead(leadId, values);
    } catch (error) {
      toast.error(t('contactForm.something_went_wrong'));
    }
  };

  const onBackStepHandler = () => {
    setStep(step - 1);
  };

  return (
    <FormWrapper>
      {(() => {
        switch (step) {
          case 1:
            return <FirstStep onSubmit={onFirstStepSubmit} formValues={formValues} />;
          case 2:
            return (
              <SecondStep
                onSubmit={onNextStepSubmit}
                onBackStepHandler={onBackStepHandler}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            );
          case 3:
            return (
              <ThirdStep onSubmit={onNextStepSubmit} onBackStepHandler={onBackStepHandler} formValues={formValues} />
            );
          case 4:
            return (
              <FourthStep onSubmit={onNextStepSubmit} onBackStepHandler={onBackStepHandler} formValues={formValues} />
            );
          case 5:
            return (
              <FifthStep onSubmit={onNextStepSubmit} onBackStepHandler={onBackStepHandler} formValues={formValues} />
            );
          case 6:
            return <SixthStep />;
          default:
            break;
        }
      })()}
    </FormWrapper>
  );
};

export default ContactForm;
