import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';

export const StyledNavbar = styled.nav`
  background: ${(p) => p.theme.dark_blue};
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: -4px 4px 8px rgba(37, 30, 103, 0.4);
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  width: 64rem;
`;

export const NavbarLogo = styled(Link)`
  color: ${(p) => p.theme.white};
  justify-self: start;
  margin-left: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  width: 7.75rem;

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: 12.75rem;
  }
`;

export const MenuIcon = styled.div`
  display: none;

  svg {
    color: ${(p) => p.theme.white};
    position: relative;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    display: block;
    position: absolute;
    top: 0.8rem;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;

    svg {
      font-size: 2rem;
    }

    p {
      color: ${(p) => p.theme.white};
      font-size: 1rem;
      font-weight: 900;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }
  }
`;

export const NavMenu = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 0.625rem;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;

  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 5rem;
`;

export const NavLink = styled(Link)`
  color: ${(p) => p.theme.light_blue};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  :hover {
    padding-bottom: 0;
    border-bottom: 0.25rem solid ${(p) => p.theme.light_blue};
    transition: all 0.2s ease-out;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    color: ${(props) => (props.current === 'true' ? props.theme.white : props.theme.light_blue)};
    font-weight: ${(props) => (props.current === 'true' ? 900 : 400)};
  }
`;

export const NavHashLink = styled(HashLink)`
  color: ${(p) => p.theme.light_blue};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  :hover {
    padding-bottom: 0;
    border-bottom: 0.25rem solid ${(p) => p.theme.light_blue};
    transition: all 0.2s ease-out;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    color: ${(props) => (props.current === 'true' ? props.theme.white : props.theme.light_blue)};
    font-weight: ${(props) => (props.current === 'true' ? 900 : 400)};
  }
`;

export const DetailsWrapper = styled.div`
  display: none;
  margin-top: 0.7rem;
  margin-left: 2rem;

  & p {
    font-size: 0.875rem;
    color: ${(p) => p.theme.white};
    display: inline;
    margin-left: 0.5rem;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: block;
  }
`;

export const MobileCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.25rem;

  & svg {
    cursor: pointer;
  }
`;

export const MobileNavHashLink = styled(HashLink)`
  color: ${(p) => p.theme.dark_blue};
  text-decoration: none;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: ${(props) => (props.current === 'true' ? 900 : 400)};
  display: block;

  :hover {
    font-weight: 600;
    transition: all 0.2s ease-out;
  }
`;

export const MobileDetailsWrapper = styled.div`
  margin-top: auto;

  & p {
    font-size: 0.875rem;
    font-weight: 300;
    color: ${(p) => p.theme.dark_blue};
    display: inline;
    margin-left: 0.5rem;
  }
`;
