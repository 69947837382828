import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { fontsizes } from 'utils/fontsizes';

export const ContentBox = styled.div`
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: ${ (p) => p.theme.white_blue };
  box-shadow: 5px 6px 14px rgba(0, 0, 0, 0.1);
`;

export const Description = styled.p`
  font-size: ${ fontsizes.p };
  margin-bottom: 1.5rem;
`;

export const ImageTitleWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  & h5 {
    font-size: ${ fontsizes.h5 };
    font-weight: 400;
    margin-left: 1rem;
  }

  @media only screen and (min-width: ${ breakpoints.sm }px) {
    font-size: ${ fontsizes.h5_sm };
  }

`;
