import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Row, Col, Space } from 'antd';

import { StyledForm, ReachOutLabel } from './styled';

const FifthStep = ({ onSubmit, formValues, onBackStepHandler }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();

  return (
    <StyledForm form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
      <ReachOutLabel>{t('contactForm.tell_about_you')}</ReachOutLabel>
      <Row gutter={8}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.first_name')} name="first_name">
            <Input placeholder={t('contactForm.first_name')} defaultValue={formValues.first_name} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.last_name')} name="last_name">
            <Input placeholder={t('contactForm.last_name')} defaultValue={formValues.last_name} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.street')} name="street">
            <Input placeholder={t('contactForm.street')} defaultValue={formValues.street} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.house_number')} name="street2">
            <Input placeholder={t('contactForm.house_number')} defaultValue={formValues.street2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.zip_code')} name="zip">
            <Input placeholder={t('contactForm.zip_code')} defaultValue={formValues.zip} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label={t('contactForm.city')} name="city">
            <Input placeholder={t('contactForm.city')} defaultValue={formValues.city} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('contactForm.email')} name="email">
        <Input placeholder={t('contactForm.email')} defaultValue={formValues.email} />
      </Form.Item>
      <Form.Item label={t('contactForm.phone')} name="phone">
        <Input placeholder={t('contactForm.phone')} defaultValue={formValues.phone} />
      </Form.Item>
      <Space>
        <Button type="default" onClick={onBackStepHandler}>
          {t('contactForm.back_btn')}
        </Button>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#70BFF9' }}>
          {t('contactForm.next_btn')}
        </Button>
      </Space>
    </StyledForm>
  );
};

export default FifthStep;
