import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'utils/useWindowDimensions';
import { eventTrack } from 'utils/googleAnalytics';
import { Button } from 'components/Button/Button';
import AllRoutes from 'pages/AllRoutes';

export const newTabButton = (t, size) => (
  <Button
    onClick={() => {
      window.open('https://solarrechner.eturnity.io/solarwatt/?utm_source=Solarbaer&utm_medium=SolarcalculatorDE');
      eventTrack('Calculator Button', 'Calculator Button click', 'Button');
    }}
    buttonStyle="dark"
    buttonSize="full"
    additionalStyles={{ width: size === 'sm' || size === 'md' ? '18.75rem' : '100%' }}>
    <p>{t('common.to_solar_calculator')}</p>
  </Button>
);

export const SolarCalculatorButton = () => {
  const { t } = useTranslation('translation');
  const { size } = useWindowDimensions();

  const iFrameButton = (
    <Link to={AllRoutes.Calculator}>
      <Button
        buttonStyle="dark"
        buttonSize="large"
        additionalStyles={{ width: '18.75rem' }}
        onClick={() => eventTrack('Calculator Button', 'Calculator Button click', 'Button')}>
        <p>{t('common.to_solar_calculator')}</p>
      </Button>
    </Link>
  );

  return size === 'xs' || size === 'sm' || size === 'md' ? newTabButton(t, size) : iFrameButton;
};
