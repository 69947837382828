import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import useContentful from 'services/useContentful';

import { PostTitle, PostBody, PostImage, DetailedPostWrapper } from './styled';

const EnergieManagementPost = () => {
  const { id } = useParams();
  const { getEnergieManagementSinglePost } = useContentful();

  const [postData, setPostData] = useState();

  const getData = useCallback(async () => {
    try {
      const data = await getEnergieManagementSinglePost(id);
      setPostData(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { data } = node;
        const { file } = data.target.fields;

        return <PostImage src={file.url} alt="Post" />;
      },
    },
  };

  return postData ? (
    <DetailedPostWrapper>
      <PostTitle>{postData.fields.postTitle}</PostTitle>
      <PostBody>{documentToReactComponents(postData.fields.detailedPostBody, renderOptions)}</PostBody>
    </DetailedPostWrapper>
  ) : (
    <div />
  );
};

export default EnergieManagementPost;
