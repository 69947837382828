import React from 'react';

import { BackgroundContainer, CardDeckContainer, CardContainer, StyledContentContainer } from './styled';

export const Background = ({ children, bgColor, additionalStyles }) => (
  <BackgroundContainer style={{ backgroundColor: bgColor, ...additionalStyles }}>{children}</BackgroundContainer>
);

export const ContentContainer = ({ children, bgColor, additionalStyles }) => (
  <StyledContentContainer style={{ backgroundColor: bgColor, ...additionalStyles }}>{children}</StyledContentContainer>
);

export const CardDeck = ({ children }) => <CardDeckContainer>{children}</CardDeckContainer>;

export const TextCard = ({ children }) => <CardContainer>{children}</CardContainer>;
