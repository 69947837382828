import ApiService from './ApiService';

const url = '/api.php/lead';

const LeadService = {
  createLead: (data) => ApiService.post(url, data),
  updateLead: (id, data) => ApiService.put(`${url}/${id}`, data),
};

export default LeadService;
