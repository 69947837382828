import styled from 'styled-components';
import { Col, Row } from 'antd';

import dayBackground from 'assets/images/day-background.svg';
import nightBackground from 'assets/images/night-background.svg';
import { breakpoints } from 'utils/breakpoints';
import { fontsizes } from 'utils/fontsizes';

export const HeaderSection = styled.h1`
  margin-bottom: 2rem;
  font-size: ${fontsizes.h1_sm};
  font-weight: 600;

  @media only screen and (min-width: ${breakpoints.sm}px) {
    font-size: ${fontsizes.h1};
  }
`;

export const HeroTextbox = styled.div`
  top: 20%;
  width: 100%;
  max-width: 64rem;

  & h1 {
    color: ${(props) => (props.isDark ? props.theme.light_blue : props.theme.dark_blue)};
    font-size: ${fontsizes.h1};
    font-weight: 600;
    text-align: left;
  }

  & h2 {
    color: ${(props) => (props.isDark ? props.theme.white : props.theme.black)};
    font-weight: lighter;
    font-size: ${fontsizes.h2};
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  @media only screen and (max-width: ${breakpoints.sm}px) {
    & h1 {
      font-size: ${fontsizes.h1_sm};
      font-weight: 600;
    }

    & h2 {
      font-size: ${fontsizes.h2_sm};
    }
  }
`;

export const HeroContainer = styled.div`
  padding: 0 0 3rem 0;
  background: ${(props) => `url(${props.isDark ? nightBackground : dayBackground}) center center/cover no-repeat`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  object-fit: contain;
  position: relative;

  @media only screen and (min-width: ${breakpoints.sm}px) {
    padding: 2rem 0 3rem 0;
  }
`;

export const ThemeButtonWrapper = styled.div`
  display: flex;
  background-color: ${(p) => (p.isDark ? p.theme.light_gray_opacity : p.theme.light_gray)};
  border-radius: 0.375rem;
  height: 4rem;
  padding: 0.5rem;

  @media only screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2.25rem;
    height: 6.625rem;
    width: 26.5rem;
    margin-bottom: 1rem;
    margin-top: 2.25rem;
  }

  @media only screen and (min-width: ${breakpoints.md}px) {
    margin: auto;
    margin-top: 2.25rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const ContactModalButtonWrapper = styled.div`
  display: flex;
  & button {
    width: 100%;
  }

  @media only screen and (min-width: ${breakpoints.sm}px) {
    & button {
      width: 26.5rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.md}px) {
    & button {
      margin: auto;
    }
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    & button {
      margin: 0;
      width: 18rem;
    }
  }
`;

export const ContactButtonWrapper = styled.div`
  width: 11.5rem;
  z-index: 2;

  & button {
    background-color: ${(p) => p.theme.light_blue};
    border-color: ${(p) => p.theme.light_blue};
    p {
      color: ${(p) => p.theme.white};
    }
  }

  @media only screen and (max-width: ${breakpoints.lg}px) {
    position: fixed;
    bottom: 7rem;
    left: -4.625rem;
    transform: rotate(90deg);

    button {
      height: 2.25rem;
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    width: 11.5rem;
    margin-left: auto;

    button {
      height: 3.375rem;
    }
  }
`;

export const StepWrapper = styled.div`
  border-left: 0.5rem solid ${(props) => (props.isActive ? props.theme.light_blue : props.theme.gray)};
  display: flex;
  padding: 1rem;
  cursor: pointer;
`;

export const StepNumber = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => (props.isActive ? props.theme.light_blue : props.theme.gray)};
  margin-right: 1rem;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  & h3 {
    font-size: ${fontsizes.h3_sm};
    font-weight: ${(props) => (props.isActive ? '700' : '400')};
    margin-bottom: 1rem;
  }
  & p {
    font-size: ${fontsizes.p};
  }

  @media only screen and (min-width: ${breakpoints.sm}px) {
    & h3 {
      font-size: ${fontsizes.h3};
    }
  }
`;

export const StepsImageWrapper = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 3.125rem;

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const CarouselSlideWrapper = styled.div`
  position: relative;
`;

export const CarouselTextContent = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 1.875rem;
  padding-right: 1.25rem;
  text-align: left;

  & h5 {
    color: ${(p) => p.theme.white};
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  & p {
    color: ${(p) => p.theme.white};
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  & button {
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: 400;
    color: ${(p) => p.theme.white};
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    width: 40%;
    top: 7.5rem;
    left: 2.5rem;
    padding-right: 0rem;
  }
`;

export const SectionHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  margin-bottom: 2rem;

  & button {
    width: 2.375rem;
  }
  & button:first-child {
    margin-right: 3.875rem;
  }
`;

export const PartnersDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h5 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  & p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  & a {
    font-size: 1rem;
    font-weight: 600;
    color: ${(p) => p.theme.black};
    text-decoration: underline;
  }
`;

export const PartnerCard = styled(Col)`
  margin-bottom: 3.75rem;

  & img {
    margin-bottom: 1.125rem;
    filter: drop-shadow(6px 6px 10px rgba(0, 0, 0, 0.1));
  }
  & h5 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  & p {
    font-size: 1rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-bottom: 0;
  }
`;

export const QuestionsSectionWrapper = styled(Col)`
  margin-top: 3.75rem;

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 1.25rem;
  }
`;

export const QuestionAnswerWrapper = styled.div`
  border-bottom: ${(p) => p.active && `0.25rem solid ${p.theme.light_blue}`};
  margin-bottom: 1.75rem;

  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    cursor: pointer;
  }
  & h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  & p {
    font-size: 0.75rem;
    margin-bottom: 1.75rem;
    display: ${(p) => (p.active ? 'block' : 'none')};
  }
`;

export const MoreQuestionsWrapper = styled.div`
  margin: auto;
  margin-top: 1rem;
  text-align: center;

  & p {
    color: ${(p) => p.theme.black};
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
  & a {
    color: ${(p) => p.theme.black};
    text-decoration: underline;
    margin: auto;
  }
`;

export const ImageBackgroundSection = styled.div`
  padding: 2rem 1rem 3rem 1rem;
  background: ${(props) => `url(${props.backgroundImg}) center center/cover no-repeat`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
`;

export const ContentCard = styled.div`
  border-radius: 0.5rem;
  background-color: ${(p) => p.theme.white};
  padding: 1.5rem !important;
`;

export const AffiliateProgramWrapper = styled.div`
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: ${(p) => p.theme.dirty_white};
  padding: 2.625rem 1.5rem;

  & h5 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  & p,
  li {
    font-size: 1rem;
    color: ${(p) => p.theme.black};
    opacity: 0.7;
  }
  & li {
    margin-left: 1rem;
    margin-bottom: 0.75rem;
  }
  & button {
    margin-top: 0.75rem;
  }
  & .e-book {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: ${(p) => p.theme.light_blue};
  }
  & .e-book span {
    font-weight: 700;
    color: ${(p) => p.theme.bright_green};
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    padding: 2.25rem 6.125rem;
    & .e-book {
      font-size: 2.75rem;
      margin-top: 0;
    }
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  & img {
    margin-right: 0.625rem;
  }
  & .name {
    font-size: 1.5rem;
  }
  & .city {
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const BlogSubtitleWrapper = styled(Row)`
  margin-bottom: 1.5rem;
  & div:not(:first-child) {
    display: inline-flex;
    justify-content: flex-end;
  }
`;

export const BlogContentWrapper = styled.div`
  margin-top: 1rem;

  & h5 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  & p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  & label {
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const StoryItemWrapper = styled(Col)`
  text-align: center;
  margin-bottom: 2.25rem;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & h5 {
    font-size: ${fontsizes.h1_sm};
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  & p {
    font-size: ${fontsizes.p};
  }

  @media only screen and (min-width: ${breakpoints.sm}px) {
    & h5 {
      font-size: ${fontsizes.h1};
    }
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-bottom: 0;

    & div {
      height: 12.75rem;
    }
  }
`;

export const LearnMoreButton = styled.button`
  background-color: ${(p) => p.theme.light_blue};
  border-color: ${(p) => p.theme.light_blue};
  color: ${(p) => p.theme.white};
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

export const MainTitle = styled.h1`
  display: none;
`;
