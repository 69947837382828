import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Tag, Space } from 'antd';

import { StyledForm, StyledTagGroup } from './styled';

const SecondStep = ({ onSubmit, formValues, setFormValues, onBackStepHandler }) => {
  const { t } = useTranslation('translation');
  const { CheckableTag } = Tag;
  const [form] = Form.useForm();

  return (
    <StyledForm form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
      <Form.Item
        label={t('contactForm.what_interests')}
        name="interests"
        validateTrigger="onBlur"
        validateFirst
        rules={[
          () => ({
            validator() {
              if (
                formValues.to_what_extent_already_informed_about_pv === 'no' &&
                formValues.storage_yes_no === 'no' &&
                formValues.charging_technology === 'no'
              ) {
                return Promise.reject(new Error(t('contactForm.choose_one_option')));
              }

              return Promise.resolve();
            },
          }),
        ]}>
        <StyledTagGroup>
          <CheckableTag
            key="to_what_extent_already_informed_about_pv"
            checked={formValues.to_what_extent_already_informed_about_pv === 'yes'}
            onChange={(checked) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                to_what_extent_already_informed_about_pv: checked ? 'yes' : 'no',
              }))
            }>
            {t('contactForm.photovoltaik')}
          </CheckableTag>
          <CheckableTag
            key="storage_yes_no"
            checked={formValues.storage_yes_no === 'yes'}
            onChange={(checked) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                storage_yes_no: checked ? 'yes' : 'no',
              }))
            }>
            {t('contactForm.battery')}
          </CheckableTag>
          <CheckableTag
            key="charging_technology"
            checked={formValues.charging_technology === 'yes'}
            onChange={(checked) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                charging_technology: checked ? 'yes' : 'no',
              }))
            }>
            {t('contactForm.wallbox')}
          </CheckableTag>
        </StyledTagGroup>
      </Form.Item>

      <Space>
        <Button type="default" onClick={onBackStepHandler}>
          {t('contactForm.back_btn')}
        </Button>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#70BFF9' }}>
          {t('contactForm.next_btn')}
        </Button>
      </Space>
    </StyledForm>
  );
};

export default SecondStep;
