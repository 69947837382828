import axios from 'axios';

export const defaultConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const apiInstance = axios.create(defaultConfig);

export const refreshInstance = axios.create(defaultConfig);
