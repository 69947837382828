import React from 'react';

const SunInactive = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4074 18.9134C16.8552 18.9134 19.6502 16.1184 19.6502 12.6706C19.6502 9.22282 16.8552 6.42783 13.4074 6.42783C9.95964 6.42783 7.16465 9.22282 7.16465 12.6706C7.16465 16.1184 9.95964 18.9134 13.4074 18.9134Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M13.4074 0.185059C13.8384 0.185059 14.1878 0.534432 14.1878 0.965405V4.08679C14.1878 4.51777 13.8384 4.86714 13.4074 4.86714C12.9765 4.86714 12.6271 4.51777 12.6271 4.08679V0.965405C12.6271 0.534432 12.9765 0.185059 13.4074 0.185059Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M13.4074 20.4741C13.8384 20.4741 14.1878 20.8235 14.1878 21.2544V24.3758C14.1878 24.8068 13.8384 25.1562 13.4074 25.1562C12.9765 25.1562 12.6271 24.8068 12.6271 24.3758V21.2544C12.6271 20.8235 12.9765 20.4741 13.4074 20.4741Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M25.893 12.6706C25.893 13.1016 25.5436 13.451 25.1126 13.451H21.9912C21.5603 13.451 21.2109 13.1016 21.2109 12.6706C21.2109 12.2396 21.5603 11.8903 21.9912 11.8903H25.1126C25.5436 11.8903 25.893 12.2396 25.893 12.6706Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M5.60396 12.6706C5.60396 13.1016 5.25458 13.451 4.82361 13.451H1.70222C1.27125 13.451 0.921875 13.1016 0.921875 12.6706C0.921875 12.2396 1.27125 11.8903 1.70222 11.8903H4.82361C5.25458 11.8903 5.60396 12.2396 5.60396 12.6706Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M22.236 3.84204C22.5408 4.14679 22.5408 4.64087 22.236 4.94562L20.0289 7.15277C19.7241 7.45752 19.2301 7.45752 18.9253 7.15277C18.6206 6.84803 18.6206 6.35394 18.9253 6.0492L21.1325 3.84204C21.4372 3.5373 21.9313 3.5373 22.236 3.84204Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M7.88954 18.1885C8.19428 18.4933 8.19428 18.9874 7.88954 19.2921L5.68238 21.4993C5.37764 21.804 4.88355 21.804 4.57881 21.4993C4.27406 21.1945 4.27406 20.7004 4.57881 20.3957L6.78596 18.1885C7.0907 17.8838 7.58479 17.8838 7.88954 18.1885Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M22.236 21.4993C21.9313 21.804 21.4372 21.804 21.1325 21.4993L18.9253 19.2921C18.6206 18.9874 18.6206 18.4933 18.9253 18.1886C19.2301 17.8838 19.7241 17.8838 20.0289 18.1886L22.236 20.3957C22.5408 20.7005 22.5408 21.1946 22.236 21.4993Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M7.88954 7.15279C7.58479 7.45754 7.09071 7.45754 6.78596 7.15279L4.57881 4.94564C4.27406 4.64089 4.27406 4.1468 4.57881 3.84206C4.88355 3.53731 5.37764 3.53731 5.68238 3.84206L7.88954 6.04921C8.19428 6.35396 8.19428 6.84805 7.88954 7.15279Z"
      fill="white"
      fillOpacity="0.1"
    />
  </svg>
);

export default SunInactive;
