import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThanksSections } from './styled';

const SixthStep = () => {
  const { t } = useTranslation('translation');

  return (
    <ThanksSections>
      <h3>{t('contactForm.thank_you')}</h3>
      <p>{t('contactForm.get_in_touch_soon')}</p>
    </ThanksSections>
  );
};

export default SixthStep;
