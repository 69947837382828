import { createClient } from 'contentful';

const useContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  const getEnergieManagementPosts = async () => {
    try {
      const entries = await client.getEntries({
        content_type: 'energieManagementPost',
        select: ['fields', 'sys.id'],
      });

      const sanitizedEntries = entries.items.map((item) => {
        const postImage = item.fields.postImage?.fields;
        return {
          ...item.fields,
          postImage,
          id: item.sys.id,
        };
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(error);
    }
  };

  const getEnergieManagementSinglePost = async (postId) => {
    try {
      const entries = await client.getEntry(postId);

      return entries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getEnergieManagementPosts, getEnergieManagementSinglePost };
};

export default useContentful;
