import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Space, Checkbox } from 'antd';

import { StyledForm, ReachOutLabel, StyledCheckbox } from './styled';

const ThirdStep = ({ onSubmit, formValues, onBackStepHandler }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();

  const [storageInfo, setStorageInfo] = useState(formValues.storage_info);
  const [emergencyPower, setEmergencyPower] = useState(formValues.emergency_power_yes_no);

  return (
    <StyledForm form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
      {formValues.to_what_extent_already_informed_about_pv === 'yes' && (
        <>
          <h3 style={{ marginBottom: '0.5rem' }}>{t('contactForm.photovoltaik')}</h3>
          <Form.Item label={t('contactForm.yearly_electrical_consumption')} name="household_electricity_consumption">
            <Input
              placeholder={t('contactForm.consumption')}
              style={{ marginBottom: '0.75rem' }}
              defaultValue={formValues.household_electricity_consumption}
              type="float"
              onChange={(e) => {
                form.setFieldValue('household_electricity_consumption', e.target.value.replace(',', '.'));
              }}
              suffix="kWh"
            />
            <Checkbox
              onChange={() => {
                form.setFieldValue('household_electricity_consumption', 0.0);
              }}>
              {t('contactForm.dont_know')}
            </Checkbox>
          </Form.Item>
        </>
      )}

      {formValues.storage_yes_no === 'yes' && (
        <>
          <h3 style={{ marginBottom: '0.5rem' }}>{t('contactForm.battery')}</h3>
          <ReachOutLabel>{t('contactForm.battery_objective')}</ReachOutLabel>
          <Form.Item name="storage_info" style={{ marginBottom: '0.5rem' }}>
            <StyledCheckbox
              checked={storageInfo === 'Maximierung von Eigenbedarf.'}
              onChange={(e) => {
                form.setFieldValue('storage_info', e.target.checked ? 'Maximierung von Eigenbedarf.' : 'none');
                setStorageInfo(e.target.checked ? 'Maximierung von Eigenbedarf.' : '');
              }}>
              {t('contactForm.maximizing_own_demand')}
            </StyledCheckbox>
          </Form.Item>
          <Form.Item name="emergency_power_yes_no" style={{ marginBottom: '0.5rem' }}>
            <StyledCheckbox
              checked={emergencyPower === 'fully_islandable'}
              onChange={(e) => {
                form.setFieldValue('emergency_power_yes_no', e.target.checked ? 'fully_islandable' : 'none');
                setEmergencyPower(e.target.checked ? 'fully_islandable' : 'none');
              }}>
              {t('contactForm.spare_power')}
            </StyledCheckbox>
          </Form.Item>
          <StyledCheckbox style={{ display: 'flex', marginBottom: '1.5rem' }}>
            {t('contactForm.dont_know')}
          </StyledCheckbox>
        </>
      )}

      {formValues.charging_technology === 'yes' && (
        <>
          <h3 style={{ marginBottom: '0.5rem' }}>{t('contactForm.wallbox')}</h3>
          <Form.Item label={t('contactForm.which_car')} name="e_car_type_comment">
            <Input placeholder={t('contactForm.car_brand')} defaultValue={formValues.e_car_type_comment} />
          </Form.Item>
        </>
      )}

      <Space>
        <Button type="default" onClick={onBackStepHandler}>
          {t('contactForm.back_btn')}
        </Button>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#70BFF9' }}>
          {t('contactForm.next_btn')}
        </Button>
      </Space>
    </StyledForm>
  );
};

export default ThirdStep;
