import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CookiesConsent from 'components/CookiesConsent/CookiesConsent';
import MainLayout from 'layouts/MainLayout';
import I18nWrapper from 'I18nWrapper';
import theme from './theme';
import './App.css';

const App = () => (
  <ThemeProvider theme={theme}>
    <I18nWrapper>
      <MainLayout>
        <Outlet />
        <ToastContainer style={{ zIndex: 9999999 }} />
      </MainLayout>
      <CookiesConsent />
    </I18nWrapper>
  </ThemeProvider>
);

export default App;
