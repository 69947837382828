import React, { useEffect } from 'react';

import googleAnalytics from 'utils/googleAnalytics';
import HeroSection from './components/HeroSection';
import StepsSection from './components/StepsSection';
import FavouriteProducts from './components/FavouriteProducts';
import StrongPartners from './components/StrongPartners';
import FAQ from './components/FAQ';
import OurServices from './components/OurServices';
import Testimonials from './components/Testimonials';
import NewsBlog from './components/NewsBlog';
import BrandingStory from './components/BrandingStory';
import { MainTitle } from './components/styled';

const Home = () => {
  useEffect(() => {
    googleAnalytics();
  }, []);

  return (
    <>
      <MainTitle>
        SolarBär GmbH - Ihr Experte für Photovoltaik, E-Auto Ladesäulen, und Energielösungen in Lörrach
      </MainTitle>
      <HeroSection />
      <StepsSection />
      <NewsBlog />
      <FavouriteProducts />
      <StrongPartners />
      {/* <Testimonials /> */}
      <OurServices />
      <BrandingStory />
      {/* <FAQ /> */}
    </>
  );
};

export default Home;
