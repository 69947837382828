import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import ContactForm from 'components/ContactForm/ContactForm';

const ContactModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('translation');

  return (
    <Modal
      title={t('contactForm.contact_modal_title')}
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={null}>
      <ContactForm />
    </Modal>
  );
};

export default ContactModal;
