import React from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const MainLayout = ({ children }) => (
  <>
    <Navbar />
    {children || <Outlet />}
    <Footer />
  </>
);

export default MainLayout;
