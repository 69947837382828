import styled from 'styled-components';

export const CalculatorContainer = styled.div`
  width: 100%;
  margin-top: 4rem;
  padding-top: 2rem;
  background-color: ${(p) => p.theme.dark_green};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0.625rem;

  & iframe {
    min-height: 53rem;
    min-width: 62rem;
  }
`;
