import { apiInstance } from '../axios';

const ApiService = {
  get: (url, config) => apiInstance.get(url, config),

  post: (url, data, config) => apiInstance.post(url, data, config),

  put: (url, data, config) => apiInstance.put(url, data, config),
};

export default ApiService;
