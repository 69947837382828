import React, { useEffect } from 'react';

import googleAnalytics from 'utils/googleAnalytics';
import { SolarCalculator } from './components/Calculator';

const CalculatorPage = () => {
  useEffect(() => {
    googleAnalytics();
  }, []);

  return <SolarCalculator />;
};

export default CalculatorPage;
