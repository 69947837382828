import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import ModulesCharacteristicsImg from 'assets/images/modules-characteristics.png';

const ModuleCharacteristicsModal = ({ isModalOpen, handleCancel }) => {
  const { t } = useTranslation('translation');

  return (
    <Modal
      title={t('home.modules_characteristics')}
      open={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}>
      <img src={ModulesCharacteristicsImg} alt={t('home.modules_characteristics')} style={{ width: '100%' }} />
    </Modal>
  );
};

export default ModuleCharacteristicsModal;
