import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'utils/useWindowDimensions';
import SVGLOGO from 'assets/images/LogoToolbar';
import AllRoutes from 'pages/AllRoutes';
import SolarCalculatorBox from 'components/SolarCalculatorBox/SolarCalculatorBox';
import {
  StyledNavbar,
  NavbarContainer,
  NavbarLogo,
  MenuIcon,
  NavMenu,
  NavItem,
  NavLink,
  NavHashLink,
  DetailsWrapper,
  MobileCloseWrapper,
  MobileNavHashLink,
  MobileDetailsWrapper,
} from './styled';

const Navbar = () => {
  const { size } = useWindowDimensions();
  const location = useLocation();
  const { t } = useTranslation('translation');

  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => setMenuOpen(!menuOpen);
  const closeMobileMenu = () => setMenuOpen(false);

  return (
    <>
      <StyledNavbar>
        <NavbarContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NavbarLogo to={AllRoutes.Home} onClick={closeMobileMenu}>
              <SVGLOGO />
            </NavbarLogo>
            <DetailsWrapper>
              <FontAwesomeIcon icon={faPhone} color="#70BFF9" />
              <p>+49 (0) 1520 4227023</p>
            </DetailsWrapper>
          </div>
          <MenuIcon onClick={handleClick} role="presentation">
            {!menuOpen && <p>{t('common.menu')}</p>}
          </MenuIcon>
          <NavMenu>
            <NavItem>
              <NavLink to={AllRoutes.Home} current={(location.pathname === AllRoutes.Home).toString()}>
                {t('common.home')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={AllRoutes.Calculator} current={(location.pathname === AllRoutes.Calculator).toString()}>
                {t('common.solar_calculator')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavHashLink to={AllRoutes.Contact} current={(location.pathname === AllRoutes.Contact).toString()}>
                {t('common.contact')}
              </NavHashLink>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </StyledNavbar>
      {(size === 'xs' || size === 'sm' || size === 'md') && (
        <Drawer
          title=""
          placement="left"
          closable={false}
          onClose={closeMobileMenu}
          open={menuOpen}
          style={{
            background:
              'linear-gradient(90.64deg, #CEE7F5 -14.83%, rgba(216, 223, 227, 0) 87.76%, #C7D9E2 116.16%), linear-gradient(186.57deg, #FFFFFF 5.07%, rgba(255, 255, 255, 0) 24.7%), rgba(255, 255, 255, 0.4)',
            boxShadow: '-3.01735px 3.01735px 6.0347px rgba(37, 30, 103, 0.4)',
            backdropFilter: 'blur(2.5px)',
            borderTopRightRadius: '0.75rem',
            borderBottomRightRadius: '0.75rem',
          }}
          bodyStyle={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <MobileCloseWrapper>
            <FontAwesomeIcon icon={faTimes} color="#141414" fontSize={24} onClick={closeMobileMenu} />
          </MobileCloseWrapper>
          <MobileNavHashLink
            to={AllRoutes.Home}
            onClick={closeMobileMenu}
            current={(location.pathname === AllRoutes.Home).toString()}>
            {t('common.home')}
          </MobileNavHashLink>
          <MobileNavHashLink
            to={AllRoutes.Calculator}
            onClick={closeMobileMenu}
            current={(location.pathname === AllRoutes.Calculator).toString()}>
            {t('common.solar_calculator')}
          </MobileNavHashLink>
          <MobileNavHashLink
            to={AllRoutes.Contact}
            onClick={closeMobileMenu}
            current={(location.pathname === AllRoutes.Contact).toString()}>
            {t('common.contact')}
          </MobileNavHashLink>
          <SolarCalculatorBox noTitle />
          <MobileDetailsWrapper>
            <FontAwesomeIcon icon={faPhone} color="#70BFF9" />
            <p>+49 (0) 1520 4227023</p>
          </MobileDetailsWrapper>
        </Drawer>
      )}
    </>
  );
};

export default Navbar;
