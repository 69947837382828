import React from 'react';

const MoonInactive = () => (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37049 0.619406C9.65485 0.966084 9.75192 1.48857 9.49597 1.9582C8.62105 3.56356 8.12382 5.40249 8.12382 7.35935C8.12382 13.634 13.241 18.7147 19.546 18.7147C20.3675 18.7147 21.168 18.6286 21.9389 18.4653C22.4661 18.3536 22.9456 18.5919 23.2026 18.958C23.4678 19.3358 23.5234 19.8994 23.1538 20.353C20.7683 23.281 17.1185 25.1562 13.0276 25.1562C5.83259 25.1562 0.00585938 19.3603 0.00585938 12.218C0.00585938 6.84267 3.3054 2.23264 8.00213 0.278837C8.54478 0.0531001 9.07706 0.261678 9.37049 0.619406Z"
      fill="#06003F"
      fillOpacity="0.1"
    />
  </svg>
);

export default MoonInactive;
