import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Background, ContentContainer } from 'components/Common/Common';
import SolarwattImg from 'assets/images/solarwatt.png';
import EntergyLogo from 'assets/images/entergy.png';
import { HeaderSection, PartnersDescriptionWrapper, PartnerCard } from './styled';

const StrongPartners = () => {
  const { t } = useTranslation('translation');

  const partners = [
    {
      logo: EntergyLogo,
      name: 'Partner name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas imperdiet massa a nulla ultricies accumsan. ',
    },
    {
      logo: EntergyLogo,
      name: 'Partner name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas imperdiet massa a nulla ultricies accumsan. ',
    },
    {
      logo: EntergyLogo,
      name: 'Partner name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas imperdiet massa a nulla ultricies accumsan. ',
    },
  ];

  return (
    <Background bgColor="#EAF0F4">
      <ContentContainer>
        <Row>
          <HeaderSection>{t('home.our_strong_partners')}</HeaderSection>
        </Row>
        <Row>
          <Col xs={24} lg={12} style={{ display: 'flex' }}>
            <PartnersDescriptionWrapper>
              <h5>{t('home.solarwatt_title')}</h5>
              <p>{t('home.solarwatt_text')}</p>
              <a href="https://www.solarwatt.com/" target="_blank" rel="noreferrer">
                {t('home.to_solarwatt_website')}
              </a>
            </PartnersDescriptionWrapper>
          </Col>
          <Col xs={24} lg={12}>
            <img src={SolarwattImg} alt="Solarwatt" style={{ width: '100%' }} />
          </Col>
        </Row>
        {/* <Row gutter={24} style={{ marginTop: 24 }}>
          {partners.map((partner, i) => (
            <PartnerCard className="gutter-row" xs={24} lg={8} key={i}>
              <img src={partner.logo} alt="Partner" />
              <h5>{partner.name}</h5>
              <p>{partner.description}</p>
            </PartnerCard>
          ))}
        </Row> */}
      </ContentContainer>
    </Background>
  );
};

export default StrongPartners;
