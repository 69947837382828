import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import ContactModal from 'components/ContactModal/ContactModal';
import { ContentContainer } from 'components/Common/Common';
import { Button } from 'components/Button/Button';
import SunActive from 'assets/images/SunActive';
import MoonActive from 'assets/images/MoonActive';
import MoonInactive from 'assets/images/MoonInactive';
import SunInactive from 'assets/images/SunInactive';
import DayHouse from 'assets/images/DayHouse';
import NightHouse from 'assets/images/NightHouse';
import { eventTrack } from 'utils/googleAnalytics';
import {
  HeroTextbox,
  HeroContainer,
  ThemeButtonWrapper,
  ContactModalButtonWrapper,
  ContactButtonWrapper,
} from './styled';

const HeroSection = () => {
  const { t } = useTranslation('translation');

  const [isDark, setIsDark] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    offers: t('home.hero_section_title'),
    areaServed: t('home.hero_section_title_area'),
    serviceOutput: t('home.hero_section_subtitle_day'),
    description: t('home.hero_section_subtitle_night'),
  };

  return (
    <>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
      <HeroContainer isDark={isDark}>
        <ContentContainer>
          <Row>
            <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 12 }}>
              <HeroTextbox isDark={isDark}>
                <h1>
                  {structuredData.offers}
                  {structuredData.areaServed}
                </h1>
                <h2>{isDark ? structuredData.description : structuredData.serviceOutput}</h2>
              </HeroTextbox>
              <ContactModalButtonWrapper>
                <Button
                  buttonStyle={isDark ? 'primary' : 'dark'}
                  buttonSize="large"
                  onClick={() => {
                    setIsContactModalOpen(true);
                    eventTrack('Hero section', 'Beratung vereinbarens Button click', 'Button');
                  }}>
                  <p>{t('home.make_appointment_btn')}</p>
                </Button>
              </ContactModalButtonWrapper>
            </Col>
            <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 12 }}>
              <ThemeButtonWrapper isDark={isDark}>
                <Button
                  buttonStyle="primary"
                  buttonSize="full"
                  onClick={() => {
                    setIsDark(false);
                    eventTrack('Hero section', 'Day theme Button click', 'Button');
                  }}
                  additionalStyles={{
                    marginRight: '0.25rem',
                    backgroundColor: isDark ? '#FFFFFF1A' : '#a2d1f4',
                    borderColor: isDark ? '#FFFFFF1A' : '#a2d1f4',
                  }}>
                  {isDark ? <SunInactive /> : <SunActive />}
                </Button>
                <Button
                  buttonStyle="primary"
                  buttonSize="full"
                  onClick={() => {
                    setIsDark(true);
                    eventTrack('Hero section', 'Night theme Button click', 'Button');
                  }}
                  additionalStyles={{
                    marginLeft: '0.25rem',
                    backgroundColor: isDark ? '#a2d1f4' : '#FFFFFF66',
                    borderColor: isDark ? '#a2d1f4' : '#FFFFFF66',
                  }}>
                  {isDark ? <MoonActive /> : <MoonInactive />}
                </Button>
              </ThemeButtonWrapper>
              <div style={{ width: '90%', margin: 'auto' }}>{isDark ? <NightHouse /> : <DayHouse />}</div>
              <ContactButtonWrapper>
                <Button
                  buttonStyle="primary"
                  buttonSize="full"
                  onClick={() => {
                    setIsContactModalOpen(true);
                    eventTrack('Hero section', 'Kontakt Button click', 'Button');
                  }}>
                  <p>
                    <FontAwesomeIcon icon={faMessage} color="#ffffff" /> Kontakt
                  </p>
                </Button>
              </ContactButtonWrapper>
            </Col>
          </Row>
        </ContentContainer>
      </HeroContainer>
      <ContactModal isOpen={isContactModalOpen} setIsOpen={setIsContactModalOpen} />
    </>
  );
};

export default HeroSection;
