import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Background, ContentContainer } from 'components/Common/Common';
import { eventTrack } from 'utils/googleAnalytics';
import PVpanel from 'assets/images/PVpanel.png';
import { HeaderSection, StepWrapper, StepContent, StepsImageWrapper } from './styled';

const OurServices = () => {
  const { t } = useTranslation('translation');

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <Background>
      <ContentContainer>
        <Row>
          <HeaderSection>{t('home.our_services')}</HeaderSection>
        </Row>
        <Row>
          <Col xs={24} lg={12}>
            <StepWrapper
              onClick={() => {
                setCurrentStep(1);
                eventTrack('Our services section', 'PV panels click', 'Services accordion');
              }}
              isActive={currentStep === 1}>
              <StepContent isActive={currentStep === 1}>
                <h3>{t('home.install_panels')}</h3>
                {currentStep === 1 && (
                  <p>{t('home.install_panels_text')}</p>
                )}
              </StepContent>
            </StepWrapper>
            <StepWrapper
              onClick={() => {
                setCurrentStep(2);
                eventTrack('Our services section', 'Wallboxes click', 'Services accordion');
              }}
              isActive={currentStep === 2}>
              <StepContent isActive={currentStep === 2}>
                <h3>{t('home.wallboxes_electric_cars')}</h3>
                {currentStep === 2 && (
                  <p>{t('home.wallboxes_electric_cars_text')}</p>
                )}
              </StepContent>
            </StepWrapper>
            <StepWrapper
              onClick={() => {
                setCurrentStep(3);
                eventTrack('Our services section', 'Solar-carports click', 'Services accordion');
              }}
              isActive={currentStep === 3}>
              <StepContent isActive={currentStep === 3}>
                <h3>{t('home.solar_carports')}</h3>
                {currentStep === 3 && (
                  <p>{t('home.solar_carports_text')}</p>
                )}
              </StepContent>
            </StepWrapper>
          </Col>
          <Col xs={24} lg={12}>
            <StepsImageWrapper>
              <img src={PVpanel} alt="Calculator" style={{ width: '100%' }} />
            </StepsImageWrapper>
          </Col>
        </Row>
      </ContentContainer>
    </Background>
  );
};

export default OurServices;
