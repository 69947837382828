import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'utils/useWindowDimensions';
import { Background, ContentContainer } from 'components/Common/Common';
import { Button } from 'components/Button/Button';
import { eventTrack } from 'utils/googleAnalytics';
import SolarModule from 'assets/images/solar-module.svg';
import HomeBattery from 'assets/images/home-battery.svg';
import SmartController from 'assets/images/smart-controler.svg';
import SolarModuleMobile from 'assets/images/solar-module-mobile.svg';
import HomeBatteryMobile from 'assets/images/home-battery-mobile.svg';
import SmartControllerMobile from 'assets/images/smart-controler-mobile.svg';
import ModuleCharacteristicsModal from './ModuleCharacteristicsModal';
import {
  CarouselSlideWrapper,
  CarouselTextContent,
  HeaderSection,
  ButtonsWrapper,
  SectionHeadingWrapper,
} from './styled';

const FavouriteProducts = () => {
  const { t } = useTranslation('translation');
  const { size, breakpoints } = useWindowDimensions();
  const swiperRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const mobileNavButtons = (
    <>
      <Button
        buttonStyle="primary"
        buttonSize="full"
        additionalStyles={{
          backgroundColor: '#70BFF9',
          borderColor: '#70BFF9',
          padding: '0.5rem',
          width: '2.375rem',
          top: -350,
          left: -138,
          position: 'relative',
        }}
        onClick={() => {
          swiperRef.current?.slidePrev();
          eventTrack('Favourite products', 'Favourite products carousel move', 'Carousel');
        }}>
        <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" fontSize={20} />
      </Button>
      <Button
        buttonStyle="primary"
        buttonSize="full"
        additionalStyles={{
          backgroundColor: '#70BFF9',
          borderColor: '#70BFF9',
          padding: '0.5rem',
          width: '2.375rem',
          top: -350,
          left: 140,
          position: 'relative',
        }}
        onClick={() => {
          swiperRef.current?.slideNext();
          eventTrack('Favourite products', 'Favourite products carousel move', 'Carousel');
        }}>
        <FontAwesomeIcon icon={faArrowRight} color="#ffffff" fontSize={20} />
      </Button>
    </>
  );

  return (
    <>
      <Background>
        <ContentContainer additionalStyles={{ width: '100%' }}>
          <SectionHeadingWrapper>
            <HeaderSection>{t('home.our_favourite_products')}</HeaderSection>
            {(size === 'lg' || size === 'xl' || size === 'xxl') && (
              <ButtonsWrapper>
                <Button
                  buttonStyle="primary"
                  buttonSize="full"
                  additionalStyles={{
                    backgroundColor: '#a2d1f4',
                    padding: '0.5rem',
                  }}
                  onClick={() => {
                    swiperRef.current?.slidePrev();
                    eventTrack('Favourite products', 'Favourite products carousel move', 'Carousel');
                  }}>
                  <FontAwesomeIcon icon={faArrowLeft} color="#ffffff" fontSize={20} />
                </Button>
                <Button
                  buttonStyle="primary"
                  buttonSize="full"
                  additionalStyles={{
                    backgroundColor: '#a2d1f4',
                    padding: '0.5rem',
                  }}
                  onClick={() => {
                    swiperRef.current?.slideNext();
                    eventTrack('Favourite products', 'Favourite products carousel move', 'Carousel');
                  }}>
                  <FontAwesomeIcon icon={faArrowRight} color="#ffffff" fontSize={20} />
                </Button>
              </ButtonsWrapper>
            )}
          </SectionHeadingWrapper>
          <Swiper
            spaceBetween={15}
            slidesPerView={1}
            modules={[Pagination, Navigation]}
            pagination={{
              clickable: true,
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            loop
            breakpoints={{
              [breakpoints.md]: {
                slidesPerView: 'auto',
                spaceBetween: 24,
              },
            }}
            className="favouriteProductsCarousel">
            <SwiperSlide>
              <CarouselSlideWrapper>
                <img
                  src={size === 'xs' || size === 'sm' || size === 'md' ? SolarModuleMobile : SolarModule}
                  alt="Background"
                  className="background"
                />
                <CarouselTextContent>
                  <h5>{t('home.solar_module')}</h5>
                  <p>{t('home.solar_module_text')}</p>
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                      eventTrack('Favourite products', 'Module chart open', 'Modal open');
                    }}>
                    {t('home.solar_module_button')}
                  </button>
                </CarouselTextContent>
                {(size === 'xs' || size === 'sm' || size === 'md') && mobileNavButtons}
              </CarouselSlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <CarouselSlideWrapper>
                <img
                  src={size === 'xs' || size === 'sm' || size === 'md' ? HomeBatteryMobile : HomeBattery}
                  alt="Background"
                  className="background"
                />
                <CarouselTextContent>
                  <h5>{t('home.home_storage')}</h5>
                  <p>{t('home.home_storage_text')}</p>
                </CarouselTextContent>
                {(size === 'xs' || size === 'sm' || size === 'md') && mobileNavButtons}
              </CarouselSlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <CarouselSlideWrapper>
                <img
                  src={size === 'xs' || size === 'sm' || size === 'md' ? SmartControllerMobile : SmartController}
                  alt="Background"
                  className="background"
                />
                <CarouselTextContent>
                  <h5>{t('home.energy_manager')}</h5>
                  <p>{t('home.energy_manager_text')}</p>
                </CarouselTextContent>
                {(size === 'xs' || size === 'sm' || size === 'md') && mobileNavButtons}
              </CarouselSlideWrapper>
            </SwiperSlide>
          </Swiper>
        </ContentContainer>
      </Background>
      <ModuleCharacteristicsModal isModalOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)} />
    </>
  );
};

export default FavouriteProducts;
