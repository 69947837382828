import { useState, useEffect } from 'react';

import { breakpoints } from './breakpoints';

function getWindowDimensions() {
  if (window.visualViewport) {
    const { width, height } = window.visualViewport;
    return { width, height };
  }
  return { width: window.innerWidth, height: window.innerHeight };
}

export const sizes = {
  xs: {
    min: breakpoints.xs,
    max: breakpoints.sm,
  },
  sm: {
    min: breakpoints.sm,
    max: breakpoints.md,
  },
  md: {
    min: breakpoints.md,
    max: breakpoints.lg,
  },
  lg: {
    min: breakpoints.lg,
    max: breakpoints.xl,
  },
  xl: {
    min: breakpoints.xl,
    max: breakpoints.xxl,
  },
  xxl: {
    min: breakpoints.xxl,
    max: Infinity,
  },
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [mediaSize, setMediaSize] = useState('xs');
  const [supportsMediaQueryListEvent, setSupportsMediaQueryListEvent] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.visualViewport?.addEventListener('resize', handleResize);
    return () => window.visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const queries = Object.keys(sizes).map((key) => {
      const sizeKey = key;

      const matchMedia = window.matchMedia(
        sizeKey !== 'xxl'
          ? `(min-width: ${sizes[sizeKey].min}px) and (max-width: ${sizes[sizeKey].max - 1}px)`
          : `(min-width: ${sizes[sizeKey].min}px)`
      );

      if (matchMedia.matches) setMediaSize(sizeKey);

      const handler = (e) => {
        if (e.matches) setMediaSize(sizeKey);
      };

      setSupportsMediaQueryListEvent(!!matchMedia.addEventListener);
      !!matchMedia.addEventListener && matchMedia.addEventListener('change', handler);

      return {
        matchMedia,
        handler,
      };
    });

    return () => {
      queries.forEach((query) => {
        query.matchMedia.removeEventListener('change', query.handler);
      });
    };
  }, []);
  const getSize = () => {
    let size = 'xs';

    if (windowDimensions.width >= breakpoints.sm && windowDimensions.width < breakpoints.md) {
      size = 'sm';
    } else if (windowDimensions.width >= breakpoints.md && windowDimensions.width < breakpoints.lg) {
      size = 'md';
    } else if (windowDimensions.width >= breakpoints.lg && windowDimensions.width < breakpoints.xl) {
      size = 'lg';
    } else if (windowDimensions.width >= breakpoints.xl && windowDimensions.width < breakpoints.xxl) {
      size = 'xl';
    } else if (windowDimensions.width >= breakpoints.xxl) {
      size = 'xxl';
    }
    return size;
  };

  return {
    width: windowDimensions.width,
    height: windowDimensions.height,
    size: supportsMediaQueryListEvent ? mediaSize : getSize(),
    breakpoints,
  };
}
