import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import useQuery from 'utils/useQuery';
import { langs } from './utils/i18n';

const languageIsValid = (language) => Object.keys(langs).filter((lng) => language.split('-')[0] === lng).length > 0;

const I18nWrapper = ({ children }) => {
  const { i18n } = useTranslation();

  const query = useQuery();
  const lang = query.get('lang');

  useEffect(() => {
    if (lang && languageIsValid(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return <div>{children}</div>;
};

export default I18nWrapper;
