import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';

import { StyledForm, ReachOutLabel } from './styled';

const FirstStep = ({ onSubmit, formValues }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();

  return (
    <StyledForm form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
      <ReachOutLabel>{t('contactForm.how_to_reach')}</ReachOutLabel>

      <Form.Item
        dependencies={['phone']}
        validateTrigger="onBlur"
        name="email"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && !getFieldValue('phone') && !formValues.email && !formValues.phone) {
                return Promise.reject(new Error(t('contactForm.provide_email_or_phone')));
              }

              if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                return Promise.reject(new Error(t('contactForm.valid_email_address')));
              }

              return Promise.resolve();
            },
          }),
        ]}>
        <Input placeholder={t('contactForm.email')} defaultValue={formValues.email} />
      </Form.Item>

      <ReachOutLabel>{t('contactForm.and_or')}</ReachOutLabel>

      <Form.Item
        dependencies={['email']}
        validateTrigger="onBlur"
        name="phone"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value || getFieldValue('email') || formValues.phone || formValues.email) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('contactForm.provide_email_or_phone')));
            },
          }),
        ]}>
        <Input placeholder={t('contactForm.phone')} defaultValue={formValues.phone} />
      </Form.Item>

      <Button type="primary" htmlType="submit" style={{ backgroundColor: '#70BFF9' }}>
        {t('contactForm.next_btn')}
      </Button>
    </StyledForm>
  );
};

export default FirstStep;
