import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';

import { LegalContainer } from 'components/Common/styled';
import googleAnalytics from 'utils/googleAnalytics';

const Impressum = () => {
  const { t } = useTranslation('translation');

  useEffect(() => {
    googleAnalytics();
  }, []);

  return (
    <LegalContainer>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{t('impressum.impressum_content')}</ReactMarkdown>
    </LegalContainer>
  );
};

export default Impressum;
