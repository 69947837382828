import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  background-color: ${(p) => p.theme.dirty_white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 3rem 1rem;
  overflow: hidden;
`;

export const CardDeckContainer = styled.div`
  width: 100%;
  max-width: 70rem;
`;

export const CardContainer = styled.div`
  margin-bottom: 1rem;
  padding: 2rem 1rem 3rem 1rem;
  background-color: ${(p) => p.theme.white};
  border-radius: 0.625rem;
`;

export const StyledContentContainer = styled.div`
  margin: 1rem;
  max-width: 64rem;
`;

export const LegalContainer = styled.div`
  margin: 2rem 1rem 1rem 1rem;
  color: ${(p) => p.theme.dark_blue};

  & h1 {
    margin-top: 1rem;
  }

  & h2 {
    margin-top: 0.8rem;
  }

  & h3 {
    margin-top: 0.6rem;
  }

  & h4 {
    margin-top: 0.4rem;
  }

  & p {
    margin-top: 0.2rem;
  }
`;
