import React from 'react';

const MoonActive = () => (
  <svg width="36" height="36" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.1769 1.29987C22.8433 2.11601 23.0708 3.34602 22.471 4.45162C20.4204 8.23089 19.255 12.56 19.255 17.1668C19.255 31.9384 31.2484 43.8992 46.0257 43.8992C47.9512 43.8992 49.8272 43.6965 51.6342 43.312C52.8698 43.0491 53.9935 43.6101 54.5959 44.4721C55.2175 45.3614 55.3478 46.6883 54.4816 47.7561C48.8906 54.649 40.3363 59.0635 30.7483 59.0635C13.8849 59.0635 0.228516 45.419 0.228516 28.605C0.228516 15.9505 7.9618 5.09768 18.9698 0.498114C20.2416 -0.0333085 21.4891 0.457719 22.1769 1.29987Z"
      fill="white"
    />
  </svg>
);

export default MoonActive;
