import ReactGA from 'react-ga4';

const googleAnalytics = () => {
  ReactGA.initialize([
    {
      trackingId: 'G-3MRMG0D4GC',
    },
  ]);
};

export default googleAnalytics;

export const eventTrack = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
