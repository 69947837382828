import styled from 'styled-components';

import { breakpoints } from 'utils/breakpoints';

export const FooterLinks = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const FooterLinkItems = styled.div`
  flex: 1 1 0rem;
  display: flex;
  flex-direction: column;

  & h5 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
  }

  & a {
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 0.5rem;
    color: ${(p) => p.theme.black};
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialIconLink = styled.a`
  color: ${(p) => p.theme.bright_blue};
  font-size: 1.5rem;
  margin-right: 2.75rem;
`;

export const SectionTitle = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.625rem;

  @media only screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & svg {
    margin-right: 0.25rem;
  }
  & p {
    margin-bottom: 0.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}px) {
    flex-direction: row;
    justify-content: space-between;
    & p {
      margin-bottom: 0;
    }
  }
`;

export const LogoWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a {
    font-size: 1rem;
    font-weight: 700;
    color: ${(p) => p.theme.black};
    text-decoration: none;
  }
  & svg {
    width: 10rem;
  }
`;
