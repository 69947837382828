import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Space } from 'antd';

import { StyledForm } from './styled';

const FourthStep = ({ onSubmit, formValues, onBackStepHandler }) => {
  const { t } = useTranslation('translation');
  const [form] = Form.useForm();

  return (
    <StyledForm form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
      <Form.Item label={t('contactForm.tell_more_plans')} name="phone_appointment_other_info">
        <Input.TextArea
          placeholder={t('contactForm.tell_us_more')}
          style={{ minHeight: '10rem' }}
          defaultValue={formValues.phone_appointment_other_info}
        />
      </Form.Item>

      <Space>
        <Button type="default" onClick={onBackStepHandler}>
          {t('contactForm.back_btn')}
        </Button>
        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#70BFF9' }}>
          {t('contactForm.next_btn')}
        </Button>
      </Space>
    </StyledForm>
  );
};

export default FourthStep;
