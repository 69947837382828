import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'utils/useWindowDimensions';
import { SolarCalculatorButton } from 'components/CalculatorButton/CalculatorButton';
import { Background, ContentContainer } from 'components/Common/Common';
import { eventTrack } from 'utils/googleAnalytics';
import FirstImg from 'assets/images/steps-section-first.png';
import SecondImg from 'assets/images/steps-section-second.png';
import ThirdImg from 'assets/images/steps-section-third.png';
import { HeaderSection, StepWrapper, StepNumber, StepContent, StepsImageWrapper } from './styled';

const StepsSection = () => {
  const { t } = useTranslation('translation');
  const { size } = useWindowDimensions();

  const [currentStep, setCurrentStep] = useState(1);

  const getStepImage = (currentStep) => {
    if (currentStep === 1) return FirstImg;
    if (currentStep === 2) return SecondImg;
    if (currentStep === 3) return ThirdImg;
  };

  return (
    <Background>
      <ContentContainer>
        <Row>
          <HeaderSection>{t('home.photovoltaic_system_steps')}</HeaderSection>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <StepWrapper
              onClick={() => {
                setCurrentStep(1);
                eventTrack('Steps section', 'Step 1 click', 'Step 1');
              }}
              isActive={currentStep === 1}>
              <StepNumber isActive={currentStep === 1}>1</StepNumber>
              <StepContent isActive={currentStep === 1}>
                <h3>{t('home.calculator_step_title')}</h3>
                {currentStep === 1 && (
                  <>
                    <p style={{ marginBottom: '1rem' }}>{t('home.calculator_step_text')}</p>
                    <SolarCalculatorButton />
                    <img src={getStepImage(currentStep)} alt="Calculator" style={{ width: '100%', display: size === 'xs' || size === 'sm' ? 'block' : 'none' }} />
                  </>
                )}
              </StepContent>
            </StepWrapper>
            <StepWrapper
              onClick={() => {
                setCurrentStep(2);
                eventTrack('Steps section', 'Step 2 click', 'Step 2');
              }}
              isActive={currentStep === 2}>
              <StepNumber isActive={currentStep === 2}>2</StepNumber>
              <StepContent isActive={currentStep === 2}>
                <h3>{t('home.consultation_step_title')}</h3>
                {currentStep === 2 && (
                  <>
                    <p style={{ marginBottom: '1rem' }}>{t('home.consultation_step_text')}</p>
                    <img src={getStepImage(currentStep)} alt="Calculator" style={{ width: '100%', display: size === 'xs' || size === 'sm' ? 'block' : 'none' }} />
                  </>
                )}
              </StepContent>
            </StepWrapper>
            <StepWrapper
              onClick={() => {
                setCurrentStep(3);
                eventTrack('Steps section', 'Step 3 click', 'Step 3');
              }}
              isActive={currentStep === 3}>
              <StepNumber isActive={currentStep === 3}>3</StepNumber>
              <StepContent isActive={currentStep === 3}>
                <h3>{t('home.installation_step_title')}</h3>
                {currentStep === 3 && (
                  <>
                    <p style={{ marginBottom: '1rem' }}>{t('home.installation_step_text')}</p>
                    <img src={getStepImage(currentStep)} alt="Calculator" style={{ width: '100%', display: size === 'xs' || size === 'sm' ? 'block' : 'none' }} />
                  </>
                )}
              </StepContent>
            </StepWrapper>
          </Col>
          <Col xs={24} md={12}>
            <StepsImageWrapper>
              <img src={getStepImage(currentStep)} alt="Calculator" style={{ width: '100%', display: size === 'xs' || size === 'sm' ? 'none' : 'block' }} />
            </StepsImageWrapper>
          </Col>
        </Row>
      </ContentContainer>
    </Background>
  );
};

export default StepsSection;
