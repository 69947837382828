import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'antd';
import 'swiper/css/pagination';
import 'swiper/css';
import { useTranslation } from 'react-i18next';
import slugify from 'react-slugify';

import { ContentContainer } from 'components/Common/Common';
import NewsBlogBackground from 'assets/images/blog-background.png';
import useContentful from 'services/useContentful';
import {
  ImageBackgroundSection,
  BlogSubtitleWrapper,
  HeaderSection,
  ContentCard,
  BlogContentWrapper,
  LearnMoreButton,
  BlogGrid,
} from './styled';

const NewsBlog = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { getEnergieManagementPosts } = useContentful();

  const [blogArticles, setBlogArticles] = useState();

  const getEntries = useCallback(async () => {
    try {
      const entries = await getEnergieManagementPosts();
      setBlogArticles(entries);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getEntries();
  }, []);

  return (
    <ImageBackgroundSection backgroundImg={NewsBlogBackground}>
      <ContentContainer additionalStyles={{ width: '100%' }}>
        <HeaderSection>{t('home.blog_news_title')}</HeaderSection>
        <BlogSubtitleWrapper>
          <Col xs={24} lg={12}>
            <p>{t('home.blog_news_subtitle')}</p>
          </Col>
        </BlogSubtitleWrapper>
        <BlogGrid>
          {blogArticles?.map((article) => (
            <ContentCard className="row-gutter" xs={24} key={article.id}>
              {article.postImage && (
                <img src={article.postImage?.file.url} alt="Article cover" style={{ width: '100%' }} />
              )}
              <BlogContentWrapper>
                <h5>{article.postTitle}</h5>
                {article.postBody && <p>{article.postBody}</p>}
                <LearnMoreButton
                  onClick={() => navigate(`/energiemanagement/${article.id}/${slugify(article.postTitle)}`)}>
                  {t('home.blog_news_button')}
                </LearnMoreButton>
              </BlogContentWrapper>
            </ContentCard>
          ))}
        </BlogGrid>
      </ContentContainer>
    </ImageBackgroundSection>
  );
};

export default NewsBlog;
