import React from 'react';

const SunActive = () => (
  <svg width="36" height="36" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.4072 51.7079C43.8168 51.7079 51.4447 44.08 51.4447 34.6704C51.4447 25.2608 43.8168 17.6328 34.4072 17.6328C24.9976 17.6328 17.3696 25.2608 17.3696 34.6704C17.3696 44.08 24.9976 51.7079 34.4072 51.7079Z"
      fill="#FFE55B"
    />
    <path
      d="M34.4072 0.595215C35.5834 0.595215 36.5369 1.54871 36.5369 2.72491V11.2437C36.5369 12.4199 35.5834 13.3734 34.4072 13.3734C33.231 13.3734 32.2775 12.4199 32.2775 11.2437V2.72491C32.2775 1.54871 33.231 0.595215 34.4072 0.595215Z"
      fill="#FFE55B"
    />
    <path
      d="M34.4072 55.9673C35.5834 55.9673 36.5369 56.9208 36.5369 58.097V66.6158C36.5369 67.792 35.5834 68.7455 34.4072 68.7455C33.231 68.7455 32.2775 67.792 32.2775 66.6158V58.097C32.2775 56.9208 33.231 55.9673 34.4072 55.9673Z"
      fill="#FFE55B"
    />
    <path
      d="M68.4823 34.6704C68.4823 35.8466 67.5288 36.8001 66.3526 36.8001H57.8338C56.6576 36.8001 55.7041 35.8466 55.7041 34.6704C55.7041 33.4942 56.6576 32.5407 57.8338 32.5407H66.3526C67.5288 32.5407 68.4823 33.4942 68.4823 34.6704Z"
      fill="#FFE55B"
    />
    <path
      d="M13.1102 34.6704C13.1102 35.8466 12.1567 36.8001 10.9805 36.8001H2.46173C1.28553 36.8001 0.332031 35.8466 0.332031 34.6704C0.332031 33.4942 1.28553 32.5407 2.46173 32.5407H10.9805C12.1567 32.5407 13.1102 33.4942 13.1102 34.6704Z"
      fill="#FFE55B"
    />
    <path
      d="M58.5019 10.5757C59.3336 11.4074 59.3336 12.7559 58.5019 13.5876L52.4782 19.6113C51.6465 20.443 50.2981 20.443 49.4664 19.6113C48.6347 18.7796 48.6347 17.4311 49.4664 16.5994L55.4901 10.5757C56.3218 9.74403 57.6702 9.74403 58.5019 10.5757Z"
      fill="#FFE55B"
    />
    <path
      d="M19.3479 49.7297C20.1796 50.5614 20.1796 51.9099 19.3479 52.7416L13.3242 58.7653C12.4926 59.597 11.1441 59.597 10.3124 58.7653C9.4807 57.9336 9.4807 56.5851 10.3124 55.7534L16.3361 49.7297C17.1678 48.898 18.5162 48.898 19.3479 49.7297Z"
      fill="#FFE55B"
    />
    <path
      d="M58.5019 58.7653C57.6702 59.597 56.3218 59.597 55.4901 58.7653L49.4664 52.7416C48.6347 51.9099 48.6347 50.5615 49.4664 49.7298C50.2981 48.8981 51.6465 48.8981 52.4782 49.7298L58.5019 55.7535C59.3336 56.5852 59.3336 57.9336 58.5019 58.7653Z"
      fill="#FFE55B"
    />
    <path
      d="M19.3479 19.6113C18.5162 20.443 17.1678 20.443 16.3361 19.6113L10.3124 13.5876C9.48071 12.7559 9.48071 11.4075 10.3124 10.5758C11.1441 9.74408 12.4926 9.74408 13.3243 10.5758L19.3479 16.5995C20.1796 17.4312 20.1796 18.7796 19.3479 19.6113Z"
      fill="#FFE55B"
    />
  </svg>
);

export default SunActive;
