import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Background, ContentContainer } from 'components/Common/Common';
import SolarCalculatorBox from 'components/SolarCalculatorBox/SolarCalculatorBox';
import SvgLogoInverted from 'assets/images/LogoInverted';
import AllRoutes from 'pages/AllRoutes';
import {
  FooterLinks,
  FooterLinkItems,
  SocialIconsWrapper,
  SocialIconLink,
  SectionTitle,
  ContactsWrapper,
  LogoWrapper,
} from './styled';

const Footer = () => {
  const { t } = useTranslation('translation');

  return (
    <Background bgColor="#EAF0F4" additionalStyles={{ boxShadow: '-13px -4px 12px rgba(0, 0, 0, 0.12)' }}>
      <ContentContainer additionalStyles={{ width: '100%' }}>
        <Row gutter={24} style={{ marginBottom: '1rem' }}>
          <Col xs={24} lg={12} className="gutter-row">
            <SolarCalculatorBox />
          </Col>
          <Col xs={24} lg={12} className="gutter-row">
            <FooterLinks>
              <FooterLinkItems>
                <h5>{t('common.legal')}</h5>
                <Link to={AllRoutes.Privacy}>{t('common.data_protection')}</Link>
                <Link to={AllRoutes.Impressum}>{t('common.imprint')}</Link>
              </FooterLinkItems>
              <FooterLinkItems>
                <h5>{t('common.about_us')}</h5>
                <Link to={AllRoutes.Contact}>{t('common.contact')}</Link>
                {/* <Link to={AllRoutes.Career}>{t('common.career')}</Link> */}
              </FooterLinkItems>
            </FooterLinks>
            <FooterLinks>
              <FooterLinkItems>
                <h5>{t('common.social_media')}</h5>
                <Link to="https://www.instagram.com/solar.baer/">{t('common.instagram')}</Link>
                {/* <Link to="/">{t('common.youtube')}</Link> */}
                {/* <Link to="/">{t('common.linkedIn')}</Link> */}
              </FooterLinkItems>
              <FooterLinkItems>
                <h5>{t('common.partner')}</h5>
                <a href="https://www.solarwatt.com/" target="_blank" rel="noreferrer">
                  {t('common.solarwatt')}
                </a>
              </FooterLinkItems>
            </FooterLinks>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12} className="gutter-row">
            <SectionTitle>{t('common.social_media')}</SectionTitle>
            <SocialIconsWrapper>
              {/* <SocialIconLink
                href="https://www.xn--solarbr-bxa.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube">
                <FontAwesomeIcon icon={faFacebook} />
              </SocialIconLink> */}
              {/* <SocialIconLink
                href="https://www.xn--solarbr-bxa.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn">
                <FontAwesomeIcon icon={faLinkedin} />
              </SocialIconLink> */}
              <SocialIconLink
                href="https://instagram.com/solar.baer?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </SocialIconLink>
              {/* <SocialIconLink
                href="https://www.xn--solarbr-bxa.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn">
                <FontAwesomeIcon icon={faTwitter} />
              </SocialIconLink> */}
            </SocialIconsWrapper>
          </Col>
          <Col xs={24} lg={12} className="gutter-row">
            <SectionTitle>{t('common.contacts')}</SectionTitle>
            <ContactsWrapper>
              <p>
                <FontAwesomeIcon icon={faPhone} />
                +49 (0) 1520 4227023
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                alexander.meister@photovoltaik-solarbaer.de
              </p>
            </ContactsWrapper>
          </Col>
        </Row>
        <LogoWrapper>
          <SvgLogoInverted />
          <Link to={AllRoutes.Privacy}>{t('common.privacy_policy')}</Link>
        </LogoWrapper>
      </ContentContainer>
    </Background>
  );
};

export default Footer;
