export const fontsizes = {
    h1: '2.625rem',
    h1_sm: '1.6rem',
    h2: '1.5rem',
    h2_sm: '1.2rem',
    h3: '1.4rem',
    h3_sm: '1.1rem',
    p: '1rem',
    h5: '1.2rem',
    h5_sm: '1.1rem'
  };
