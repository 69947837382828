import React from 'react';

import { StyledButton } from './styled';

const STYLES = ['primary', 'outline', 'dark'];
const SIZES = ['medium', 'large', 'full'];

export const Button = ({ children, type, onClick, buttonStyle, buttonSize, additionalStyles }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      design={checkButtonStyle}
      size={checkButtonSize}
      style={{ ...additionalStyles }}>
      {children}
    </StyledButton>
  );
};
