import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Background, ContentContainer } from 'components/Common/Common';
import StoryImageOne from 'assets/images/branding-story-1.png';
import StoryImageTwo from 'assets/images/branding-story-2.png';
import StoryImageThree from 'assets/images/branding-story-3.png';
import { StoryItemWrapper } from './styled';

const BrandingStory = () => {
  const { t } = useTranslation('translation');

  return (
    <Background bgColor="#EAF0F4">
      <ContentContainer>
        <Row gutter={24}>
          <StoryItemWrapper xs={24} lg={8} className="gutter-row">
            <div>
              <img src={StoryImageOne} alt="Story 1" />
            </div>
            <h5>600-1,200 €</h5>
            <p>{t('home.branding_story_household_saving')}</p>
          </StoryItemWrapper>
          <StoryItemWrapper xs={24} lg={8}>
            <div>
              <img src={StoryImageTwo} alt="Story 2" />
            </div>
            <h5>3,650 kg</h5>
            <p>{t('home.branding_story_reduced_emission')}</p>
          </StoryItemWrapper>
          <StoryItemWrapper xs={24} lg={8}>
            <div>
              <img src={StoryImageThree} alt="Story 3" />
            </div>
            <h5>6,000-8,000 kWh</h5>
            <p>{t('home.branding_story_generated_electricity')}</p>
          </StoryItemWrapper>
        </Row>
      </ContentContainer>
    </Background>
  );
};

export default BrandingStory;
